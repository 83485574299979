import { BillingPeriod, CouponObject, Currency, PlanDetails, Plans } from "../../../../types/global"
import { currencySyombols } from "../../static/currencySym"
import { plans } from "../../static/plans"

export default (currency: Currency, billingPeriod: BillingPeriod, plan: Plans, coupon: CouponObject | null) => {
    return `
    ${currencySyombols[currency]}${
        // @ts-ignore
        ((plans.find((p) => p.name === plan)?.price[billingPeriod][currency] - (coupon?.amount_off ? coupon.amount_off / 100 : 0)) * (coupon?.percent_off ? (1 - (coupon?.percent_off / 100)) : 1)).toFixed(2)}
    `

}

export const getSubscriptionPrice = (currency: Currency, billingPeriod: BillingPeriod, plan: Plans, coupon: CouponObject | null): number => {
    return ((plans.find((p) => p.name === plan)?.price[billingPeriod][currency]! - (coupon?.amount_off ? coupon.amount_off / 100 : 0)) * (coupon?.percent_off ? (1 - (coupon?.percent_off / 100)) : 1))
}