import { planFeatures, plans } from '@/static/plans'
import { Box, Paper, Text } from '@mantine/core'
import React from 'react'
import { Plans } from '../../../../types/global'

export default function PlanFeaturesTable({ plan: planName }: { plan: Plans }) {
    const plan = plans.find(p => p.name === planName)
    return (
        <Box>
            {plan?.sellingPoints.map((sp, i) => {
                const notAvailable = sp === null
                return (
                    <Paper style={{ boxShadow: "none" }} p="xs" radius={0} bg={i % 2 ? "gray.0" : "#fff"}>
                        <Text c={notAvailable ? "dimmed" : undefined} lineClamp={1} fz="sm">{sp || "None"}</Text>
                    </Paper>
                )
            })}
        </Box>
    )
}

PlanFeaturesTable.FeatureList = () => {
    return (
        <Box>
            {planFeatures.map((feature, i) => {
                return (
                    <Paper radius={0} style={{ boxShadow: "none", borderTopRightRadius: 0, borderBottomRightRadius: 0 }} p="xs" bg={i % 2 ? "gray.0" : "#fff"}>
                        <Text fw={500} fz="sm">{feature}</Text>
                    </Paper>
                )
            })}
        </Box>
    )
}
