import { SetupIntentStatus } from "@/components/stripe/SetupIntentStatusIndicator"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"

export default () => {
    const router = useRouter()
    const [setupIntentID, setSetupIntentID] = useState<string | undefined>(undefined)
    const [setupIntentStatus, setSetupIntentStatus] = useState<SetupIntentStatus>("processing")

    useEffect(() => {
        setSetupIntentID(router.query.setup_intent as string)
    }, [router])

    useEffect(() => {
        if (setupIntentStatus === "succeeded") {
            window.history.replaceState(null, '', window.location.pathname)
            setSetupIntentID("")
            setSetupIntentStatus("processing")
        }
    }, [setupIntentStatus])

    return {
        setupIntentID,
        setupIntentStatus,
        setSetupIntentStatus
    }
}