import { InviteContainer } from '@/pages/i/[id]'
import ManualGrantsProvider from '@/providers/ManualGrants.provider'
import PlatformsProvider from '@/providers/Platforms.provider'
import ThemeProvider from '@/providers/Theme.provider'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { Theme } from '../../../types/global'
import { useRouter } from 'next/router'
import { useInviteGrantStatus } from '@/utils/helpers/useGranted'
import { useMediaQuery } from '@mantine/hooks'
import InviteProvider from '@/providers/Invite.provider'
import Error from '@/components/Error'
import { Logo } from '@/components/Logo'

export default function InviteLayout({ children }: PropsWithChildren) {
    const router = useRouter()
    const { id } = router.query as { id: string }

    useEffect(() => {
        try {
            (window as any)?.HubSpotConversations?.widget?.remove();
        } catch {
            console.error("[ERROR] Failed to unload HubSpot chat widget")
        }
    })

    if (router.pathname.startsWith("/i/oauth") || router.pathname.startsWith("/i/preview")) {
        return <ManualGrantsProvider>{children}</ManualGrantsProvider>
    }
    return (
        <InviteProvider id={id}>
            <PlatformsProvider>
                <ThemeProvider>
                    <ManualGrantsProvider>
                        <InviteContainer>
                            {() => {
                                return (
                                    <>
                                        {children}
                                    </>
                                )
                            }}

                        </InviteContainer>
                    </ManualGrantsProvider>
                </ThemeProvider>
            </PlatformsProvider>
        </InviteProvider>
    )
}
