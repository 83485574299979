"use client"
import { UserContext } from '@/utils/context/User.context'
import { Avatar, Box, Button, Collapse, Divider, Modal, Paper, Text, Tooltip } from '@mantine/core'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useState } from 'react'
import { InfoSquare, PlayerPlay, Presentation } from 'tabler-icons-react'

const WelcomeModal = ({ close }: { close: () => void }) => {
    const [expanded, setExpanded] = useState(false)
    const user = useContext(UserContext)
    useEffect(() => {
        setTimeout(() => {
            setExpanded(true)
        }, 1500)
    }, [])

    return (
        <Box className='flex aic jcc fdc'>
            <Box p="sm" w="100%" className='flex' style={{ gap: 10 }}>
                <Text fz={25} className='wave'>👋</Text>
                <Text mt={5}>
                    <Text lh={1} style={{ textTransform: "uppercase" }} fw={800} fz="xs" c="dimmed">Welcome</Text>
                    <Text lineClamp={1} ta={"center"} fz="lg" fw={700}>Hey, {user?.name}</Text>
                </Text>

            </Box>
            <Divider w="100%" />
            <Paper radius={0} style={{ overflow: "hidden" }} h="fit-content">
                <video
                    style={{ width: "100%", display: "block" }}
                    controls
                    src="https://storage.googleapis.com/agencyaccess-branding-assets/videos/Reports%20dashboard%20-%2030%20April%202024.mp4"
                />
            </Paper>
            <Box p="sm">
                <Box className='flex' style={{ gap: 7 }}>
                    <Avatar src={"/images/landing_page/kevin_irl.jpeg"} />
                    <Box>
                        <Text fz="sm">"Ready to take client onboarding to the next level? Get started by watching this quick onboarding video."</Text>
                        <Box mt={7} className='flex fdc' style={{ gap: 5 }}>
                            <Text lh={1} fz="sm" fw={500}>Kevin</Text>
                            <Text lh={1} fz="xs" c="dimmed">Founder of AgencyAccess</Text>

                        </Box>
                    </Box>
                </Box>
                {/* <Paper withBorder p={"xs"} radius={10} mt={10} shadow='xs'>
                    <Box className='flex' style={{ gap: 10 }}>
                        <InfoSquare style={{ minWidth: 15 }} size={15} />
                        <Text lh={1.2} fz="sm">Want to follow along? You can dismiss this video and come back to it by clicking <Presentation size={14} /> the button in the header</Text>
                    </Box>
                </Paper> */}
                {/* <iframe src="https://storage.googleapis.com/agencyaccess-branding-assets/videos/Reports%20dashboard%20-%2030%20April%202024.mp4" style={{ width: "100%", aspectRatio: "16 / 9", marginTop: 10, borderRadius: 10 }} allow="autoplay"></iframe> */}
                {/* <video src="https://drive.google.com/file/d/1Z6s55h4IA6GuI-cXek-2pxrpGcJBuByH/view?usp=sharing the vid" width={"100%"}></video> */}
                {/* <Box mt={20} bg={"gray.0"} w="100%" style={{ aspectRatio: "16 / 9", borderRadius: 15 }} className="flex aic jcc">
                    <PlayerPlay size={35} />
                </Box> */}
                <Box className='flex fdc' style={{ gap: 5 }}>
                    <Button mt={20} size="md" fullWidth onClick={close}>Continue</Button>
                    <Button component={Link} href={"https://meetings.hubspot.com/kevindejong/demo"} target='_blank' w="100%" leftSection={<Avatar style={{ border: "1px solid rgba(0,0,0,.15)" }} size={"sm"} src={"/images/landing_page/kevin.png"} />} variant='default' size='md'>Book a tour with Kevin</Button>

                </Box>
            </Box>
        </Box >
    )
}

export default function WelcomeModalController() {
    const [open, setOpen] = useState(false)
    const router = useRouter()
    useEffect(() => {
        const { first_visit } = router.query
        if (first_visit) {
            setTimeout(() => {
                setOpen(true)
            }, 800)
        } else {
            setOpen(false)
        }
    }, [router])
    const close = () => {
        (function (history) {
            var replaceState = history.replaceState;
            history.replaceState = function (state) {
                // @ts-ignore
                if (typeof history.onpushstate == "function") {
                    // @ts-ignore
                    history.onpushstate({ state: state });
                }
                // ... whatever else you want to do
                // maybe call onhashchange e.handler
                // @ts-ignore
                return replaceState.apply(history, arguments);
            };
        })(window.history);
        setOpen(false)
        window.history.replaceState({}, document.title, "/" + "dashboard")
    }
    return (
        <Modal
            radius={10}
            opened={open}
            onClose={close}
            withCloseButton={false}
            centered

            zIndex={100000}
            styles={{ overlay: { backdropFilter: "blur(5px)" }, body: { padding: 0 } }}
            transitionProps={{
                transition: "pop",
                duration: 1000,
                exitDuration: 300
            }}
        >
            <WelcomeModal close={close} />
        </Modal>
    )
}
