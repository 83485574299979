import { firestore } from '@/utils/firebase'
import { Box, Button, Loader, Text } from '@mantine/core'
import { doc, onSnapshot } from 'firebase/firestore'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import { AlertCircle } from 'tabler-icons-react'

export type SetupIntentStatus = "canceled" | "processing" | "requires_action" | "requires_payment_method" | "succeeded"

const IntentProcessing = () => {
    return (
        <Box className='flex aic jcc fdc' style={{ gap: 0 }}>
            <Loader color="dark" size={"sm"} />
            <Text mt={10} fw={500} fz="md">Setting up your payment method</Text>
            <Text c="dimmed" fz="sm">This should only take a few seconds.</Text>
        </Box>
    )
}
const IntentRequiresAction = () => {
    return (
        <Box className='flex aic jcc fdc' style={{ gap: 0 }}>
            <Loader color="dark" size={"sm"} />
            <Text mt={10} fw={500} fz="md">Please complete the action</Text>
            <Text c="dimmed" fz="sm">There should be a popup on your screen now</Text>
        </Box>
    )
}

const IntentFailed = ({ failComponent }: { failComponent: React.ReactElement }) => {
    return (
        <Box className='flex aic jcc fdc' style={{ gap: 0 }}>
            <AlertCircle color="var(--mantine-color-red-6)" />
            <Text mt={10} fw={500} fz="md">Something went wrong</Text>
            <Text ta={"center"} c="dimmed" fz="sm">We were unable to setup your new payment method for future billing. Please return to checkout and try again.</Text>
            {failComponent}
        </Box>
    )
}

export default function SetupIntentStatusIndicator({ setupIntentID, failComponent, setSetupIntentStatus }: { setupIntentID?: string, failComponent: React.ReactElement, setSetupIntentStatus?: (status: SetupIntentStatus) => void }) {
    const [intSetupIntentStatus, setIntSetupIntentStatus] = useState<SetupIntentStatus>("processing")

    useEffect(() => {
        if (!setupIntentID) {
            return
        }
        const unsub = onSnapshot(doc(firestore, "setupIntents", setupIntentID), (snap) => {
            if (!snap.exists()) {
                return
            }
            const intentStatus = snap.data()?.status

            setIntSetupIntentStatus(intentStatus)
        })

        return () => unsub()
    }, [setupIntentID])

    useEffect(() => {
        if (!setSetupIntentStatus) return;
        setSetupIntentStatus(intSetupIntentStatus)
    }, [intSetupIntentStatus])

    return (
        <>
            {intSetupIntentStatus === "processing" &&
                < IntentProcessing />
            }
            {intSetupIntentStatus === "requires_action" &&
                < IntentRequiresAction />
            }
            {(intSetupIntentStatus === "canceled" || intSetupIntentStatus === "requires_payment_method") &&
                < IntentFailed failComponent={failComponent} />
            }
        </>
    )
}
