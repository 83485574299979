import { AgencyContext } from '@/utils/context/Agency.context'
import { SubscriptionContext } from '@/utils/context/Subscription.context'
import { ActionIcon, Avatar, Badge, Box, Button, Divider, Loader, Modal, Overlay, Paper, SegmentedControl, Spoiler, Text, Tooltip, Transition, UnstyledButton } from '@mantine/core'
import Link from 'next/link'
import React, { useContext, useEffect, useState } from 'react'
import { AlertCircle, ArrowRight, InfoCircle, Tex } from 'tabler-icons-react'
import { BillingPeriod, Plans } from '../../../../types/global'
import { IconTag, IconX } from '@tabler/icons-react'
import { plans } from '@/static/plans'
import PlanFeaturesTable from '../landing/PlanFeaturesTable'
import { CurrencyContext } from '@/utils/context/Currency.context'
import { currencySyombols } from '@/static/currencySym'
import usePaymentMethods from '@/hooks/usePaymentMethods'
import { AddPaymentMethodModal } from './settings/Billing'
import SetupIntentProvider, { SetupIntentForm } from '../stripe/SetupIntentProvider'
import { useRouter } from 'next/router'
import useSetupIntentID from '@/hooks/useSetupIntentID'
import SetupIntentStatusIndicator from '../stripe/SetupIntentStatusIndicator'
import api from '@/utils/api'
import { showNotification } from '@mantine/notifications'
import { UserContext } from '@/utils/context/User.context'
import { CouponContext } from '@/utils/context/Coupon.context'
import getFormattedSubscriptionPrice, { getSubscriptionPrice } from '@/utils/data/getFormattedSubscriptionPrice'

const FreePlanUpgradePrompt = () => {
    const agency = useContext(AgencyContext)
    const user = useContext(UserContext)
    const [discountTooltipOpen, setDiscountTooltipOpen] = useState(true)
    const [billingPeriod, setBillingPeriod] = useState<BillingPeriod>("Yearly")

    useEffect(() => {
        setTimeout(() => {
            setDiscountTooltipOpen(false)
        }, 6000);
    }, [])

    return (
        <Paper h="fit-content" maw={800} shadow='xs' p={0} style={{ overflow: "hidden", position: "relative" }}>
            <Box bg="blue" py={20} className='flex aic jcsb' px={20}>
                <Text fw={700} c="#fff">📝 Pricing Update</Text>
                <UnstyledButton component={Link} href={"/auth/select"} className='flex' style={{ gap: 10 }}>
                    <Box className='flex fdc' style={{ gap: 3 }}>
                        <Text ta={"right"} c="#fff" fz="sm" lh={1} fw={600}>{agency.name}</Text>
                        <Text ta={"right"} c="#fff" fz="xs" lh={1}>{user?.name}</Text>
                    </Box>
                    <Avatar color='#fff' size={30}>{agency.name[0]}</Avatar>
                </UnstyledButton>
            </Box>
            <Box>
                <Box p="sm" className='flex aic jcsb'>
                    <Box>
                        <Text fw={700} fz={22}>Select a new plan</Text>
                        <Text fz="sm" c="dimmed">The free plan has been discontinued. You'll need to choose a new plan to continue using AgencyAccess.</Text>
                    </Box>
                    <SegmentedControl
                        value={billingPeriod}
                        onChange={(e) => setBillingPeriod(e as any)}
                        m={'md'}
                        styles={{ root: { minWidth: 240 } }}
                        data={[
                            { label: "Monthly", value: "Monthly" },
                            {
                                label:
                                    <Box className='flex aic jcc' style={{ gap: 10 }} >
                                        <Text fz="sm" fw={500}>Yearly</Text>
                                        <Tooltip opened={discountTooltipOpen} position='bottom' withinPortal withArrow zIndex={9999} multiline label={<>
                                            <Text fz="xs" fw={600}>Returning Customer Discount</Text>
                                            <Text fz="xs">We're currently offering returning customers 50% off an annual subscription</Text>
                                        </>}>
                                            <Badge leftSection={<IconTag size={12} />} color='red.6' radius={5}>-50%</Badge>
                                        </Tooltip>
                                    </Box>
                                ,
                                value: "Yearly"
                            }
                        ]}
                    />

                </Box>
                <PlanTable billingPeriod={billingPeriod} />
            </Box>
        </Paper >
    )
}

const RenewSubscriptionPrompt = () => {
    const agency = useContext(AgencyContext)
    const user = useContext(UserContext)
    const [billingPeriod, setBillingPeriod] = useState<BillingPeriod>("Yearly")

    return (
        <Paper h="fit-content" maw={800} shadow='xs' p={0} style={{ overflow: "hidden", position: "relative" }}>
            <Box bg="red" py={20} className='flex aic jcsb' px={20}>
                <Text fw={700} c="#fff">{"❌"} Subscription Expired</Text>
                <UnstyledButton component={Link} href={"/auth/select"} className='flex' style={{ gap: 10 }}>
                    <Box className='flex fdc' style={{ gap: 3 }}>
                        <Text ta={"right"} c="#fff" fz="sm" lh={1} fw={600}>{agency.name}</Text>
                        <Text ta={"right"} c="#fff" fz="xs" lh={1}>{user?.name}</Text>
                    </Box>
                    <Avatar color='#fff' size={30}>{agency.name[0]}</Avatar>
                </UnstyledButton>
            </Box>
            <Box>
                <Box p="sm" className='flex aic jcsb'>
                    <Box>
                        <Text fw={700} fz={22}>Select a new plan</Text>
                        <Text fz="sm" c="dimmed">Your subscription has expired. Please select a new plan to continue.</Text>
                    </Box>
                    <SegmentedControl
                        value={billingPeriod}
                        onChange={(e) => setBillingPeriod(e as any)}
                        m={'md'}
                        styles={{ root: { minWidth: 240 } }}
                        data={[
                            {
                                label: "Monthly",
                                value: "Monthly"
                            },
                            {
                                label: "Yearly",
                                value: "Yearly"
                            }
                        ]}
                    />

                </Box>
                <PlanTable billingPeriod={billingPeriod} />
            </Box>
        </Paper >
    )
}

const ReactiveSubscriptionPrompt = () => {
    const agency = useContext(AgencyContext)
    const user = useContext(UserContext)
    const [billingPeriod, setBillingPeriod] = useState<BillingPeriod>("Yearly")

    return (
        <Paper h="fit-content" maw={800} shadow='xs' p={0} style={{ overflow: "hidden", position: "relative" }}>
            <Box bg="gray" py={20} className='flex aic jcsb' px={20}>
                <Text fw={700} c="#fff">{"❌"} Subscription cancelled</Text>
                <UnstyledButton component={Link} href={"/auth/select"} className='flex' style={{ gap: 10 }}>
                    <Box className='flex fdc' style={{ gap: 3 }}>
                        <Text ta={"right"} c="#fff" fz="sm" lh={1} fw={600}>{agency.name}</Text>
                        <Text ta={"right"} c="#fff" fz="xs" lh={1}>{user?.name}</Text>
                    </Box>
                    <Avatar color='#fff' size={30}>{agency.name[0]}</Avatar>
                </UnstyledButton>
            </Box>
            <Box>
                <Box p="sm" className='flex aic jcsb'>
                    <Box>
                        <Text fw={700} fz={22}>Select a new plan</Text>
                        <Text fz="sm" c="dimmed">Your subscription has been cancelled. Please select a new plan to continue.</Text>
                    </Box>
                    <SegmentedControl
                        value={billingPeriod}
                        onChange={(e) => setBillingPeriod(e as any)}
                        m={'md'}
                        styles={{ root: { minWidth: 240 } }}
                        data={[
                            {
                                label: "Monthly",
                                value: "Monthly"
                            },
                            {
                                label: "Yearly",
                                value: "Yearly"
                            }
                        ]}
                    />

                </Box>
                <PlanTable billingPeriod={billingPeriod} />
            </Box>
        </Paper >
    )
}

const PlanTable = ({ billingPeriod }: { billingPeriod: BillingPeriod }) => {
    const currency = useContext(CurrencyContext)
    const agency = useContext(AgencyContext)
    const coupon = useContext(CouponContext)
    const [selectedPlan, setSelectedPlan] = useState<Plans | undefined>(undefined)
    return (
        <>
            {/* <Modal opened={addPaymentMethodModal} onClose={() => setAddPaymentMethodModal(false)}>
                <AddPaymentMethodModal />
            </Modal> */}
            <Box>
                <Transition transition={"slide-up"} mounted={!!selectedPlan}>
                    {(styles) => {
                        const plan = plans.find((p) => p.name === selectedPlan)
                        return (

                            <Overlay bg="#fff" style={{ ...styles, overflow: "hidden" }}>
                                <Box className='flex aic jcsb' p="md" h={60}>
                                    <Text fw={600}>Checkout</Text>
                                    <ActionIcon onClick={() => setSelectedPlan(undefined)} variant='subtle'>
                                        <IconX size={18} />
                                    </ActionIcon>
                                </Box>
                                <Divider />
                                <Box className='flex' style={{ width: "100%", height: "calc(100% - 60px)" }}>
                                    <Box p="lg" bg={"#f9f9f9"} style={{ flex: 1 }}>
                                        <Text fz={"lg"}>{selectedPlan === "Premium" ? "🏢" : (selectedPlan === "Agency" ? "💼" : "📏")}</Text>
                                        <Text fw={600} fz="lg">{selectedPlan} plan</Text>
                                        <Text fz="sm" c="dimmed">
                                            {plan?.des}
                                        </Text>
                                        <Box mt={15} className='flex fdc' style={{ gap: 10 }}>
                                            {plan?.sellingPoints.filter((sp) => !!sp).map((sp) => {
                                                return <>
                                                    <Divider opacity={.5} />
                                                    <Text fz="sm" fw={500}>• {sp}</Text>
                                                </>
                                            })}
                                        </Box>
                                    </Box>
                                    <Box p="lg" bg={"#fff"} style={{ flex: 1, overflowY: "auto" }}>
                                        <SetupIntentProvider>
                                            {(clientSecret) => {
                                                return <SetupIntentForm clientSecret={clientSecret}>
                                                    {(handleSubmit, loading) => {
                                                        return <Button fullWidth mt={10} loading={loading} onClick={() => handleSubmit(`${window.location.origin}/dashboard?subExpiredNewPlan=${plan?.name}&billingPeriod=${billingPeriod}`)}>
                                                            Pay ${(getSubscriptionPrice(currency, billingPeriod, plan?.name!, coupon) / ((billingPeriod === "Yearly" && agency.plan === "Free") ? 2 : 1)).toFixed(2)}
                                                        </Button>
                                                    }}
                                                </SetupIntentForm>
                                            }}
                                        </SetupIntentProvider>
                                    </Box>
                                </Box>
                            </Overlay>
                        )
                    }}
                </Transition>
                <Divider />
                {coupon
                    ? <>
                        <Box bg="green.0" w="100%" p="sm" className='flex' style={{ gap: 10 }}>
                            <IconTag size={16} />
                            <Box>
                                <Text fz="sm" fw={500}>
                                    {coupon.name}
                                </Text>
                                <Text style={{ textTransform: "uppercase" }} c="green" fw={600} fz="xs">{coupon.amount_off
                                    ? <>{currencySyombols[currency]}{coupon.amount_off / 100} OFF {coupon.duration === "repeating" ? `for ${coupon.duration_in_months} months` : coupon.duration}</>
                                    : <>{coupon.percent_off}% OFF {coupon.duration}</>
                                }</Text>

                            </Box>
                        </Box>
                        <Divider />
                    </>
                    : null
                }
                <Box className='flex'>
                    <Box py={"sm"} mt={100} miw={180}>
                        <Divider />
                        <PlanFeaturesTable.FeatureList />
                        <Divider />
                    </Box>
                    <Divider orientation='vertical' />

                    <Box className='flex horizontalOverflow' style={{ overflow: "auto", gap: 0 }}>
                        {plans.map((plan, i) => {
                            return (
                                <>
                                    {!!i ? <Divider opacity={.5} orientation='vertical' /> : null}
                                    <Box py="sm" miw={200}>
                                        <Box px="sm" h={100}>
                                            <Text fz={"md"}>{plan.name === "Premium" ? "🏢" : (plan.name === "Agency" ? "💼" : "📏")}</Text>
                                            <Text fz="lg" fw={600}>{plan.name}</Text>
                                            <Box h="fit-content">
                                                <Text lineClamp={2} fz="sm" c="dimmed">{plan.des}</Text>
                                                <Spoiler maxHeight={25}
                                                    fz={"sm"}
                                                    styles={{ control: { fontSize: 14, lineHeight: 1 }, }}
                                                    showLabel="Show more" hideLabel="Show less">
                                                </Spoiler>
                                            </Box>
                                        </Box>
                                        <Divider />

                                        <Box>
                                            <PlanFeaturesTable plan={plan.name} />
                                        </Box>
                                        <Divider />
                                        <Box px={"xs"} mt={10}>
                                            <Box mt={15}>
                                                <Text lh={1} fw={600} fz="lg">
                                                    {billingPeriod === "Yearly"
                                                        ? <Text lh={1} fw={600} fz="lg" span c="dimmed" style={{ textDecoration: "line-through" }}>{currencySyombols[currency]}{Math.round(plan.price["Monthly"][currency])} </Text>
                                                        : null
                                                    }
                                                    {currencySyombols[currency]}{(plan.price[billingPeriod][currency] / (billingPeriod === "Yearly" ? 12 : 1)).toFixed(0)} <Text span fz="xs" c="dimmed" lh={1}>/month</Text>
                                                </Text>
                                                {billingPeriod === "Yearly" ? <Text style={{ position: "relative", bottom: 3 }} lh={1} span fz="xs" c="dimmed">Billed anually</Text> : null}
                                            </Box>
                                            <Button onClick={() => setSelectedPlan(plan.name)} mt={10} fullWidth rightSection={<ArrowRight size={18} />}>Continue</Button>
                                        </Box>
                                    </Box>
                                </>
                            )
                        })}
                    </Box>
                </Box>
            </Box>
        </>
    )
}

const HandlePayment = ({ resetMode, plan, billingPeriod }: { resetMode: VoidFunction, plan: Plans, billingPeriod: BillingPeriod }) => {
    const agency = useContext(AgencyContext)
    const {
        setupIntentID,
        setupIntentStatus,
        setSetupIntentStatus
    } = useSetupIntentID()

    useEffect(() => {
        if (setupIntentStatus !== "succeeded") return;
        api.post("/stripe/subscribe", {
            plan,
            billingPeriod,
        }).then((res) => {
            if (res.error) {
                showNotification({ color: "red", message: res.msg, title: "Failed to subscribe" })
                return resetMode()
            }
            // Wait for agency context to update with new plan
        })
    }, [setupIntentStatus])

    useEffect(() => {
        if (agency.plan === plan && (agency.subscriptionStatus === "active" || agency.subscriptionStatus === "trialing") && (agency.subscriptionExpires || 0) > Date.now()) return window.location.reload();
    }, [agency])

    return (
        <Paper h="fit-content" p="xl" shadow='sm'>
            {setupIntentStatus !== "succeeded"
                ? <SetupIntentStatusIndicator failComponent={<Button onClick={resetMode} mt={10}>Back to plans</Button>} setupIntentID={setupIntentID} setSetupIntentStatus={setSetupIntentStatus} />
                : <Loader size={"sm"} />
            }
        </Paper>
    )
}

export default function SubscriptionInactive() {
    const router = useRouter()
    const agency = useContext(AgencyContext)
    const subscriptionActive = useContext(SubscriptionContext)
    const coupon = useContext(CouponContext)
    console.log(coupon)
    const [mode, setMode] = useState<{ mode: "select" } | { mode: "payment", plan: Plans, billingPeriod: BillingPeriod } | undefined>(undefined)

    useEffect(() => {
        const { subExpiredNewPlan, billingPeriod } = router.query
        if (subExpiredNewPlan && plans.find((p) => p.name === subExpiredNewPlan) && (["Monthly", 'Yearly'] as BillingPeriod[]).includes(billingPeriod as any)) return setMode({
            mode: "payment",
            plan: subExpiredNewPlan as any,
            billingPeriod: billingPeriod as any
        });
        setMode({ mode: "select" })
    }, [router])

    if (!mode) return <Paper p="md">
        <Loader size={"sm"} />
    </Paper>

    return (
        <Box py={50} className='flex jcc' style={{
            position: "fixed",
            height: "100dvh",
            width: "100dvw",
            background: "rgba(255,255,255,.5)",
            backdropFilter: "blur(10px)",
            zIndex: 999,
            top: 0,
            left: 0,
            overflowY: "auto"
        }}>
            {mode.mode === "payment"
                ? <HandlePayment billingPeriod={mode.billingPeriod} plan={mode.plan} resetMode={() => setMode({ mode: "select" })} />
                : <>
                    {agency.plan === "Free"
                        ? <FreePlanUpgradePrompt />
                        : <>
                            {agency.subscriptionExpires as number < Date.now() && agency.subscriptionStatus !== "canceled"
                                ? <RenewSubscriptionPrompt />
                                : <>
                                    {agency.subscriptionStatus === "canceled" && agency.subscriptionExpires as number < Date.now()
                                        ? <ReactiveSubscriptionPrompt />
                                        : null
                                    }
                                </>
                            }

                        </>
                    }
                </>
            }

        </Box>
    )
}
