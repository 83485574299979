import { AgencyContext } from '@/utils/context/Agency.context'
import { SubscriptionContext } from '@/utils/context/Subscription.context'
import { useRouter } from 'next/router'
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react'

export default function SubscriptionProvider({ children }: PropsWithChildren) {
    const router = useRouter()
    const agency = useContext(AgencyContext)
    const [subscriptionActive, setSubscriptionActive] = useState<undefined | boolean>(undefined)

    useEffect(() => {
        if (!agency) {
            return
        }
        if (Date.now() > (agency.subscriptionExpires || 0) || agency.subscriptionStatus === "canceled") {
            if (!agency?.stripeID) {
                router.push("/auth/signup/create?ref=12")
                return
            }
            if (!agency?.trialComplete) {
                router.push("/auth/signup/create?ref=13")
                return
            }
            return setSubscriptionActive(false)
        }
        // if (agency.) {
        //     return setSubscriptionActive(false)
        // }
        return setSubscriptionActive(true)
    }, [agency])

    if (subscriptionActive === undefined) return null

    return (
        <SubscriptionContext.Provider value={subscriptionActive}>
            {children}
        </SubscriptionContext.Provider>
    )
}
