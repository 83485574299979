import { Avatar, Box, BoxProps, Rating, Text } from '@mantine/core';
import React from 'react'

export default function SenjaReviews(props: { darkmode?: boolean } & Omit<BoxProps, "children">) {
    return (
        <Box {...props}>
            <Box className='flex aic' style={{ gap: 10 }}>
                <Avatar.Group>
                    {new Array(5).fill(0).map((_, i) => (
                        <Avatar src={`/images/landing_page/reviews/${i + 1}.webp`} size={34} />
                    ))}
                </Avatar.Group>
                <Box>
                    <Rating size={16} value={5} />
                    <Text c={props.darkmode ? "#fff" : "#000"} fz={14} lineClamp={1} fw={600}>From 500+ agencies</Text>
                </Box>
            </Box>
        </Box>
    )
}
