
import { Logo } from '@/components/Logo';
import { Box, Button, Divider, Text, UnstyledButton } from '@mantine/core'
import { IconChevronRight } from '@tabler/icons-react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { PropsWithChildren, useEffect, useMemo } from 'react'
import { ArrowRight } from 'tabler-icons-react';

export default function OnboardingLayout({ children }: PropsWithChildren) {
    const router = useRouter()
    const stages = [
        { path: "/onboarding", title: "Your Company" },
        { path: "/onboarding/connect", title: "Connect Accounts" },
        { path: "/onboarding/preview", title: "Preview" }
    ] as { path: string, title: string }[]

    const currentStageIdx = useMemo(() => stages.findIndex((s) => router.pathname === (`/dashboard${s.path}`)), [router.pathname])

    useEffect(() => {
        try {
            // @ts-ignore
            var gradient = new Gradient();
            gradient.initGradient("#canvas");
        } catch (err) {
            void 0
        }
    }, [])
    return (
        <Box style={{ position: "fixed", top: 0, left: 0, width: "100vw", height: "100vh", background: '#fff', zIndex: 100 }} className='flex'>
            <Box style={{ height: "100%", flex: .5, maxWidth: 200, position: 'relative' }}>
                <canvas id="canvas" style={{ left: 0, top: 0 }} />
            </Box>
            <Box id='onboarding_root_content_container' style={{ flex: 1, background: "#fff", zIndex: 500, position: 'relative' }} className='flex fdc  aic'>
                <Box h={85} pt={20} pb={20} style={{ gap: 10, width: "100%", borderBottom: "1px solid rgba(0,0,0,.1)" }} px={15} className='flex aic jcsb'>
                    <Box className='flex aic' style={{ gap: 10 }}>
                        <Logo width={200} />
                        <Divider orientation="vertical" />
                        <Box className='flex aic' style={{ gap: 10 }}>
                            {stages.map((stage, i) => {
                                const isPassed = currentStageIdx > i
                                const isCurrent = currentStageIdx === i
                                return (
                                    <>
                                        {!!i ? <IconChevronRight color={"var(--mantine-color-dimmed)"} size={18} /> : null}
                                        <UnstyledButton onClick={() => isPassed ? router.push(`/dashboard${stage.path}`) : void 0} style={{ cursor: isPassed ? "pointer" : "default" }}>
                                            <Text style={{ textDecoration: isPassed ? "line-through" : undefined }} c={!isCurrent ? "dimmed" : undefined} fw={500} fz={"sm"}>{stage.title}</Text>
                                        </UnstyledButton>

                                    </>
                                )
                            })}
                            {/* <Text fz={"sm"} c="dimmed" lh={1} fw={500}>Onboarding</Text>
                            <Text lh={1} mt={2} fw={500}>{stages.find((s) => router.pathname === (`/dashboard${s.path}`))?.title}</Text> */}
                        </Box>
                    </Box>
                    <Link href={"/dashboard"}>
                        <Button rightSection={<ArrowRight size={18} />} variant="subtle">Skip Onboarding</Button>
                    </Link>
                </Box>
                <Box style={{ flex: 1, width: "100%", overflowY: "auto" }} py={40}>

                    <Box maw={600} w="100%" mx={"auto"}>
                        {children}
                    </Box>
                </Box>

            </Box>
        </Box>
    )
}
