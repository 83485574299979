import api from '@/utils/api'
import getStripeKey from '@/utils/helpers/getStripeKey';
import { Skeleton } from '@mantine/core';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js';
import React, { PropsWithChildren, useEffect, useState } from 'react'
import Error from '../Error';

const stripePromise = loadStripe(getStripeKey());

export default function SetupIntentProvider({ children }: { children: (clientSecret: string) => React.ReactElement }) {
    const [clientSecret, setClientSecret] = useState<string | undefined>(undefined)

    useEffect(() => {
        api.post<string>("/stripe/payment-methods/add").then((res) => {
            if (!res.error) {
                setClientSecret(res.data)
            }
        })
    }, [])
    if (!clientSecret) {
        return (
            <Skeleton w={"100%"} h={150} />
        )
    }
    return (
        <Elements options={{
            clientSecret: clientSecret,
            appearance: {
                theme: "stripe"
            }
        }} stripe={stripePromise}>
            {children(clientSecret)}
        </Elements>
    )
}


export const SetupIntentForm = ({ clientSecret, children }: { clientSecret: string, children: (handleSubmit: (returnURL: string) => void, loading: boolean) => React.ReactElement }) => {
    const stripe = useStripe()
    const elements = useElements()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    useEffect(() => {
        if (!stripe) {
            return;
        }

        if (!clientSecret) {
            return;
        }

    }, [stripe]);
    const handleSubmit = async (returnURL: string) => {

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setLoading(true);

        const { error } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: returnURL
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            setError(error.message as string);
        } else {
            setError("An unexpected error occurred.");
        }

        setLoading(false);

    };
    return (
        <div>
            <PaymentElement id="payment-element" options={{ layout: "tabs" }} />
            {/* <Divider my={15} /> */}
            {children((returnURL) => handleSubmit(returnURL), loading)}
            <Error mt={20}>{error}</Error>
        </div>
    )
}