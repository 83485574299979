import { PlanDetails, Plans } from "../../../types/global";

export const planFeatures = [
    "Platforms",
    "Invite Types",
    "Branding",
    "Team Members",
    "Invites per month",
    "Integrations",
    "Support"
]

export const negativeSellingPoints = [
    "Meta & Google Access: Connect to Meta and Google securely.",
    "Full Zapier integration + Custom integrations",
    "3 Invites per Month",
    "Onboard using Google & Meta",
    "1 Team Member",
    "AgencyAccess.co branding",
    "Personalized links",
    "Standard Support",
    "Priority support",
    "Up to 10 team members",
    "Full Zapier integration",
    "10 invites per month"
] // These selling points won't be corssed out on higher tier plans

export const plans = [
    // START OF NEW PLANS
    {
        name: "Starter",
        des: "A simple and efficient solution for small agencies and freelancers looking to streamline client onboarding with ease.",
        sellingPoints: [
            "Request access to all platforms",
            "Personalized invite",
            "Add your logo",
            "Up to 3 team members",
            "5 invites",
            null,
            "Email support on weekdays"
        ],
        price: {
            Monthly: {
                "EUR": 44,
                "GBP": 44,
                "USD": 44
            },
            Yearly: {
                "EUR": 395,
                "GBP": 395,
                "USD": 395
            }
        },
        order: 2,
        limits: {
            Monthly: {
                accessLinks: 5,
                branding: false,
                logo: true,
                teammembers: 3,
                linkPreview: true,
                platforms: null,
                staticLinks: false,
                subdomain: false,
                zapier: false
            },
            Yearly: {
                accessLinks: 60,
                branding: false,
                logo: true,
                teammembers: 3,
                linkPreview: true,
                platforms: null,
                staticLinks: false,
                subdomain: false,
                zapier: false
            }
        }
    },
    {
        name: "Premium",
        price: {
            Monthly: {
                "EUR": 99,
                "GBP": 99,
                "USD": 99
            },
            Yearly: {
                "EUR": 890,
                "GBP": 890,
                "USD": 890
            }
        },
        des: "Built for growing agencies that need more flexibility, automation, and customization to scale their operations smoothly.",
        sellingPoints: [
            "Request access to all platforms",
            "Personalized + static invite",
            "Complete branding customization",
            "Up to 10 team members",
            "Unlimited invites",
            "Integrate with 7000+ tools through Zapier",
            "Priority email + chat support"
        ],
        limits: {
            Monthly: {
                // socialConnections: null,
                // prospectAccessLinks: null,
                // linkPreview: true,
                // multiAccounts: true,
                accessLinks: null,
                branding: true,
                subdomain: true,
                zapier: true,
                staticLinks: true,
                teammembers: 10,
                logo: true,
                platforms: null
            },
            Yearly: {
                // prospectAccessLinks: null,
                // socialConnections: null,
                // multiAccounts: true,
                // linkPreview: true,
                accessLinks: null,
                branding: true,
                subdomain: true,
                zapier: true,
                staticLinks: true,
                teammembers: 10,
                logo: true,
                platforms: null
            }
        },
        order: 3,
    },
    {
        name: "Agency",
        des: "The Agency plan is best for enterprise agencies with 50+ employees",
        sellingPoints: [
            "Request access to all platforms",
            "Personalized + static invite",
            "Complete branding customization",
            "Unlimited team members",
            "Unlimited invites",
            "Integrate with 7000+ tools through Zapier",
            "Priority email + chat support",
            "Multiple brands"
        ],
        comingSoon: [
            "Employee management",
            "Password vault",
            "2FA client management",
        ],
        limits: {
            Monthly: {
                // prospectAccessLinks: null,
                // socialConnections: null,
                // linkPreview: true,
                // multiAccounts: true,
                accessLinks: null,
                branding: true,
                subdomain: true,
                zapier: true,
                staticLinks: true,
                logo: true,
                platforms: null,
                teammembers: null
            },
            Yearly: {
                // prospectAccessLinks: null,
                // socialConnections: null,
                // multiAccounts: true,
                // linkPreview: true,
                accessLinks: null,
                branding: true,
                subdomain: true,
                zapier: true,
                staticLinks: true,
                logo: true,
                platforms: null,
                teammembers: null
            }
        },
        order: 4,
        price: {
            Monthly: {
                "EUR": 199,
                "GBP": 199,
                "USD": 199
            },
            Yearly: {
                "EUR": 1790,
                "GBP": 1790,
                "USD": 1790
            }
        },
    }
    // {
    //     name: "Free",
    //     price: {
    //         Monthly: {
    //             "EUR": 0,
    //             "GBP": 0,
    //             "USD": 0
    //         },
    //         Yearly: {
    //             "EUR": 0,
    //             "GBP": 0,
    //             "USD": 0
    //         }
    //     },
    //     des: "The Free plan is ideal for freelancers and small agencies",
    //     order: 1,
    //     limits: {
    //         Monthly: {
    //             accessLinks: 3,
    //             prospectAccessLinks: null,
    //             branding: false,
    //             // socialConnections: null,
    //             subdomain: false,
    //             linkPreview: false,
    //             zapier: false,
    //             // multiAccounts: false,
    //             staticLinks: false,
    //             teammembers: 1,
    //             platforms: ["Google", "Meta"],
    //             logo: false
    //         },
    //         Yearly: {
    //             accessLinks: 36,
    //             prospectAccessLinks: null,
    //             branding: false,
    //             // socialConnections: null,
    //             subdomain: false,
    //             linkPreview: false,
    //             zapier: false,
    //             // multiAccounts: false,
    //             staticLinks: false,
    //             teammembers: 1,
    //             platforms: ["Google", "Meta"],
    //             logo: false
    //         }
    //     },
    //     sellingPoints: [
    //         // "One-Link Access: Streamline client onboarding with ease.",
    //         // // "Meta & Google Access: Connect to Meta and Google securely.",
    //         // "Multi-Platform Access: Connect to any social platform securely.",
    //         // "OAuth Technology: Ensure data security with OAuth.",
    //         // "Unlimited team members"
    //         "3 Invites per Month",
    //         "Onboard using Google & Meta",
    //         "1 Team Member",
    //         "AgencyAccess.co branding",
    //         "Personalized links",
    //         "Standard Support"
    //     ],

    // },

    // END OF NEW PLANS

    // {
    //     name: "Free",
    //     price: {
    //         Monthly: {
    //             "EUR": 0,
    //             "GBP": 0,
    //             "USD": 0
    //         },
    //         Yearly: {
    //             "EUR": 0,
    //             "GBP": 0,
    //             "USD": 0
    //         }
    //     },
    //     des: "Free is ideal for freelancers and small agencies",
    //     order: 1,
    //     limits: {
    //         Monthly: {
    //             accessLinks: null,
    //             prospectAccessLinks: null,
    //             branding: false,
    //             socialConnections: null,
    //             subdomain: false,
    //             linkPreview: false,
    //             zapier: false,
    //             multiAccounts: false,
    //             staticLinks: false
    //         },
    //         Yearly: {
    //             accessLinks: null,
    //             prospectAccessLinks: null,
    //             branding: false,
    //             socialConnections: null,
    //             subdomain: false,
    //             linkPreview: false,
    //             zapier: false,
    //             multiAccounts: false,
    //             staticLinks: false
    //         }
    //     },
    //     sellingPoints: [
    //         "One-Link Access: Streamline client onboarding with ease.",
    //         // "Meta & Google Access: Connect to Meta and Google securely.",
    //         "Multi-Platform Access: Connect to any social platform securely.",
    //         "OAuth Technology: Ensure data security with OAuth.",
    //         "Unlimited team members"
    //     ]
    // },
    // {
    //     name: "Premium",
    //     price: {
    //         Monthly: {
    //             "EUR": 45,
    //             "GBP": 45,
    //             "USD": 45
    //         },
    //         Yearly: {
    //             "EUR": 325,
    //             "GBP": 325,
    //             "USD": 325
    //         }
    //     },
    //     des: "Premium is better for medium to enterprise agencies",
    //     limits: {
    //         Monthly: {
    //             accessLinks: null,
    //             prospectAccessLinks: null,
    //             branding: true,
    //             socialConnections: null,
    //             subdomain: true,
    //             linkPreview: true,
    //             zapier: true,
    //             multiAccounts: true,
    //             staticLinks: true
    //         },
    //         Yearly: {
    //             accessLinks: null,
    //             prospectAccessLinks: null,
    //             branding: true,
    //             socialConnections: null,
    //             subdomain: true,
    //             linkPreview: true,
    //             zapier: true,
    //             multiAccounts: true,
    //             staticLinks: true
    //         }
    //     },
    //     order: 2,
    //     sellingPoints: [
    //         "One-Link Access: Streamline client onboarding with ease.",
    //         "Multi-Platform Access: Connect to any social platform securely.",
    //         "OAuth Technology: Ensure data security with OAuth.",
    //         "Unlimted clients and prospects",
    //         "Unlimited team members",
    //         "Branding Options: Customize onboarding with your logo and colors.",
    //         "Zapier Integration: Add AgencyAccess to your automated workflow",
    //         "Static Links: Use the same link to onboard multiple clients",
    //         "Multi-Account Access: Link multiple Google, Meta, etc, accounts to your account"
    //     ]
    // }





    // {
    //     name: "Essential",
    //     price: {
    //         Monthly: {
    //             "EUR": 25,
    //             "GBP": 25,
    //             "USD": 29
    //         },
    //         Yearly: {
    //             "EUR": 240,
    //             "GBP": 240,
    //             "USD": 280
    //         }
    //     },
    //     des: "Ideal for Freelancers and Small Agencies",
    //     sellingPoints: [
    //         "One-Link Access: Streamline client onboarding with ease.",
    //         "Multi-Platform Access: Connect to up to 2 social platforms securely.",
    //         "Link Preview: Review onboarding links before sending.",
    //         "OAuth Technology: Ensure data security with OAuth.",
    //         "Limited to 3 Clients/Month: Manage up to 5 clients effectively.",
    //         "1 User Account: Collaborate with a team member."
    //     ],
    //     order: 2,
    //     limits: {
    //         Monthly: {
    //             accessLinks: 3,
    //             prospectAccessLinks: 5,
    //             branding: false,
    //             linkPreview: true,
    //             socialConnections: 2,
    //             subdomain: false
    //         },
    //         Yearly: {
    //             accessLinks: 36,
    //             prospectAccessLinks: 60,
    //             branding: false,
    //             linkPreview: true,
    //             socialConnections: 2,
    //             subdomain: false
    //         }
    //     }
    // },
    // {
    //     name: "Professional",
    //     price: {
    //         Monthly: {
    //             "EUR": 69,
    //             "GBP": 69,
    //             "USD": 74
    //         },
    //         Yearly: {
    //             "EUR": 660,
    //             "GBP": 660,
    //             "USD": 710
    //         }
    //     },
    //     des: "Perfect for Small to Medium-Sized Agencies",
    //     sellingPoints: [
    //         "One-Link Access: Streamline client onboarding with ease.",
    //         "Multi-Platform Access: Connect to various social platforms securely.",
    //         "Link Preview: Review onboarding links before sending.",
    //         "OAuth Technology: Ensure data security with OAuth.",
    //         "Branding Options: Customize onboarding with your logo and colors.",
    //         "Limited to 10 Clients/Month: Manage up to 10 clients effectively.",
    //         "Up to 3 Users: Collaborate with your team.",
    //         "Personalization: Tailor the experience for each client.",
    //         "Priority Support: Priority assistance for your agency's success."
    //     ],
    //     order: 3,
    //     limits: {
    //         Monthly: {
    //             accessLinks: 10,
    //             prospectAccessLinks: 20,
    //             branding: true,
    //             linkPreview: true,
    //             socialConnections: null,
    //             subdomain: false
    //         },
    //         Yearly: {
    //             accessLinks: 120,
    //             prospectAccessLinks: 240,
    //             branding: true,
    //             linkPreview: true,
    //             socialConnections: null,
    //             subdomain: false
    //         }
    //     }
    // },
    // {
    //     name: "Enterprise",
    //     price: {
    //         Monthly: {
    //             "EUR": 229,
    //             "GBP": 229,
    //             "USD": 244
    //         },
    //         Yearly: {
    //             "EUR": 2200,
    //             "GBP": 2200,
    //             "USD": 2340
    //         }
    //     },
    //     des: "Tailored for Larger Agencies and Enterprises",
    //     sellingPoints: [
    //         "One-Link Access: Streamline client onboarding with ease.",
    //         "Multi-Platform Access: Connect to various social platforms securely.",
    //         "Link Preview: Review onboarding links before sending.",
    //         "OAuth Technology: Ensure data security with OAuth.",
    //         "Branding Options: Customize onboarding with your logo and colors.",
    //         "Unlimited Clients: Scale your agency with no client limit.",
    //         "Unlimited users: Collaborate seamlessly with your team.",
    //         "Personalization: Tailor the experience for each client.",
    //         "Priority Support: Priority assistance for your agency's success.",
    //         "Custom Domain Hosting: Host on your domain for a professional touch.",
    //         "White Label: Remove all AgencyAccess branding for a fully customized experience."
    //     ],
    //     order: 4,
    //     limits: {
    //         Monthly: {
    //             accessLinks: null,
    //             prospectAccessLinks: null,
    //             branding: true,
    //             linkPreview: true,
    //             socialConnections: null,
    //             subdomain: true
    //         },
    //         Yearly: {
    //             accessLinks: null,
    //             prospectAccessLinks: null,
    //             branding: true,
    //             linkPreview: true,
    //             socialConnections: null,
    //             subdomain: true
    //         }
    //     }
    // }
] as Array<PlanDetails>;